import {
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Empty,
  Form,
  FormItem,
  Icon,
  Input,
  InputNumber,
  Loading,
  Menu,
  MenuItem,
  Message,
  MessageBox,
  Notification,
  Option,
  OptionGroup,
  Pagination,
  Popover,
  Radio,
  RadioGroup,
  RadioButton,
  Row,
  Scrollbar,
  Select,
  Submenu,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  TimeSelect,
  Tooltip,
  Transfer,
  Tree,
  Upload,
} from 'element-ui';

const setupElement = {
  install: (Vue) => {
    Vue.use(Button)
      .use(Form)
      .use(FormItem)
      .use(Input)
      .use(Row)
      .use(Col)
      .use(Breadcrumb)
      .use(BreadcrumbItem)
      .use(Dropdown)
      .use(DropdownMenu)
      .use(DropdownItem)
      .use(Dialog)
      .use(Dropdown)
      .use(Menu)
      .use(MenuItem)
      .use(Submenu)
      .use(Badge)
      .use(Tooltip)
      .use(Scrollbar)
      .use(Card)
      .use(Select)
      .use(Option)
      .use(OptionGroup)
      .use(Table)
      .use(TableColumn)
      .use(Loading.directive)
      .use(Switch)
      .use(Icon)
      .use(Pagination)
      .use(Tag)
      .use(DatePicker)
      .use(Transfer)
      .use(Tree)
      .use(Checkbox)
      .use(Upload)
      .use(Tabs)
      .use(TabPane)
      .use(Radio)
      .use(RadioGroup)
      .use(RadioButton)
      .use(TimeSelect)
      .use(Popover)
      .use(InputNumber)
      .use(Alert)
      .use(Empty);
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;
  },
};
export default setupElement;
